import React from 'react'
import CookieConsent from 'react-cookie-consent';

const AnalyticsConsent = () => (
    <CookieConsent
        location="bottom"
        buttonText="Accept"
        declineButtonText="Decline"
        cookieName="gatsby-gdpr-google-analytics">
        This site uses cookies in order to offer you the most
        relevant information. Please accept cookies for optimal performance
    </CookieConsent>
)

export default AnalyticsConsent
