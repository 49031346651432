import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { slide as Menu } from 'react-burger-menu'
import './burger-menu.css'

const BurgerMenu = () => {

    return (
        <Menu>
            <StyledMenuLink id="home" className="menu-item" to='/'>
                <MenuItem>Italy Presents Italy</MenuItem>
            </StyledMenuLink>
            <StyledMenuLink id="italian-curiosities" className="menu-item" to='/italian-curiosities/'>
                <MenuItem>Italian Curiosities</MenuItem>
            </StyledMenuLink>
            <StyledMenuLink id="italian-cuisine" className="menu-item" to='/italian-cuisine/'>
                <MenuItem>Italian Cuisine</MenuItem>
            </StyledMenuLink>
            <StyledMenuLink id="italian-heritage" className="menu-item" to='/italian-heritage/'>
                <MenuItem>Italian Heritage</MenuItem>
            </StyledMenuLink>
            <StyledMenuLink id="travel-to-italy" className="menu-item" to='/travel-to-italy/'>
                <MenuItem>Travel To Italy</MenuItem>
            </StyledMenuLink>
        </Menu>
    )
}

const StyledMenuLink = styled(Link)`
    text-decoration: none;
    color: black;
    margin: 1rem auto;
    text-align: center;

    div {
        border: 1px solid transparent;

    }
     
    &:hover {
        div {
            border: 1px solid ${props => props.theme.green};
            color: ${props => props.theme.red};
            
        }
    }
`

const MenuItem = styled.div`
  margin-right: 1rem;
  margin-left: 1rem;
  font-family: 'Inconsolata', 'monospace';
  font-size: 1rem;
  padding: .6rem;
  border-radius: .9rem;
  white-space:nowrap;
  transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
`

export default BurgerMenu
