import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { useSiteMetadata } from '../hooks/useSiteMetadata'

const LogoImage = () => {
  const { siteUrl } = useSiteMetadata()

  const data = useStaticQuery(graphql`
    query {
      logoImage: file(relativePath: { eq: "italypresentsitaly-banner.png" }) {
        childImageSharp {
          fluid(maxWidth: 915, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Link
      style={{
        background: `url('${siteUrl}${data.logoImage.childImageSharp.fluid.src}') no-repeat center center`,
        backgroundSize: '100%',
        height: '100%',
        width: '100%',
        textDecoration: 'none',
        display: 'inline-block',
      }}
      to='/'
    />
  )
}

export default LogoImage
