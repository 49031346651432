import React from 'react'
import { useStaticQuery, graphql, Link } from "gatsby"
import styled from 'styled-components'

export default function TopRecipes() {

    const data = useStaticQuery(graphql`
        query {
            getTopPlaces: 
                allMdx(filter: {frontmatter: {category: {eq: "travel"}}}, sort: {order: DESC, fields: frontmatter___placeRate}, limit: 12) {
                    nodes {
                        id
                        frontmatter {
                            place
                        }
                        fields {
                            slug
                        }
                    }
                }
        }
    `)

    return (
        <Wrapper>
            <Title>Top Places</Title>
            <PlacesList list={data.getTopPlaces.nodes} />
        </Wrapper>
    )
}

const Wrapper = styled.div`
    a {
        text-decoration: none;
    }

    @media only screen and (max-width: 1120px) { 
        display: none;
    }
`

const Title = styled.div`
    color: black;
    font-family: 'Bangers', 'cursive';
    font-size: 1.5rem;
    
`

const PlacesList = ({ list }) => (
    <List>
        {list.map((item, index) => (
            <Link 
                key={item.id}
                to={item.fields.slug}
            >
                <SingleElement><div>{++index}) {item.frontmatter.place}</div></SingleElement>
            </Link>
        ))}
    </List>
)

const List = styled.div`
    

`

const SingleElement = styled.div`
    padding: 0.3rem;
    font-family: 'Architects Daughter', 'cursive';
    color: black;
    font-size: 1.2rem;
    padding-bottom: .1rem;

    div {
        border-bottom: 1px solid transparent;
        transition: all 0.2s ease-in-out;
        -webkit-transition: all 0.2s ease-in-out;
    }

    &:hover div{
        border-bottom: 1px solid black;
        display: inline-block;
    }

    @media only screen and (max-width: 1350px) { 
        font-size: 1rem;
    }
    
`


