import React, { useState, useEffect } from 'react'
import { navigate } from 'gatsby';
import styled from 'styled-components'
import Icon from './Icon'
import magnifierIcon from '../../static/magnifier-icon.png'
//URLSEARCHPARAMS IS NOT SUPPORTED IN INTERNET EXPLORER. FORTUNATELY, THERE IS THIS POLYFILL
import 'url-search-params-polyfill';

const SearchBar = () =>  {
    
    const [keyword, setKeyword] = useState('')
    const location = typeof window !== 'undefined' ? window.location.search : '';
    const searchQuery = new URLSearchParams(location).get('keywords') || '';

    useEffect(() => {
        
        setKeyword(searchQuery)
        
    }, [location, searchQuery])

    const handleSubmit = (e) => {
        e.preventDefault()

        if(keyword.trim() !== '') {
            navigate(`/search?keywords=${encodeURIComponent(keyword)}`)
        }
    }

    return (
        <>
            <Title>Search</Title>
            <SearchInputWrapper
                role='search'
                method='GET'
                onSubmit={handleSubmit}
            >
                <SearchIconWrapper>
                    <SearchInput 
                        type="search"
                        id="search-input"
                        name="keywords"
                        aria-controls='search-results-count'
                        value={keyword}
                        onChange={e => setKeyword(e.currentTarget.value)}
                    />
                    <StyledIcon>
                        <Icon src={magnifierIcon} description={'Enter Button'}></Icon>
                    </StyledIcon>
                </SearchIconWrapper>
                
                <SeachButton type='submit'>Submit</SeachButton>
            </SearchInputWrapper>
            
        </>
    )
}

export default SearchBar

const Title = styled.div`
    color: black;
    font-family: 'Bangers', 'cursive';
    font-size: 2rem;

    @media only screen and (max-width: 1120px) {
        font-size: 1.3rem;
        margin-right: .3rem;
    }
`

const SearchInputWrapper = styled.form`
   
    display: flex;
    justify-content: center;

    @media only screen and (max-width: 1350px) {
        flex-direction: column;
    } 

    @media only screen and (max-width: 1120px) { 
        flex-direction: row;
    }
`

const SearchIconWrapper = styled.div`
    position: relative;
    
`


const SearchInput = styled.input`

    font-family: 'Inconsolata','monospace';
    font-size: 1rem;
    padding-left: 5%;
    height: 100%;
    border-top-left-radius: .5rem;
    border-bottom-left-radius: .5rem;
    border:1px solid transparent;
    box-shadow: 0 0 10px transparent;
    transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;

    &:focus {
        outline: none !important;
        border:1px solid ${props => props.theme.green};
        box-shadow: 0 0 10px ${props => props.theme.green};
    }

    @media only screen and (max-width: 1350px) {
        width: 150px;
        border-bottom-left-radius: 0;
        border-top-right-radius: .5rem;
        padding: .2rem;
    } 

    @media only screen and (max-width: 1120px) {
        border-top-right-radius: 0;
        border-bottom-left-radius: .5rem;
    }
`

const StyledIcon = styled.div`
    position: absolute;
    top: 25%;
    right: 5%;
   

    @media only screen and (max-width: 1350px) {
        display: none;
    } 
`

const SeachButton = styled.button`
    border-top-right-radius: .5rem;
    border-bottom-right-radius: .5rem;
    border:1px solid transparent;
    font-size: 10px;
    text-align: center;
    text-transform: uppercase;
    padding: 1rem;
    background-color: #48cfad;
    background: -webkit-linear-gradient(to right, ${props => props.theme.green}, white); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, ${props => props.theme.green}, white); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    color: black;
    transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;
    text-align: center;

    &:hover {
       
        background: -webkit-linear-gradient(to right, white, ${props => props.theme.green}); /* Chrome 10-25, Safari 5.1-6 */
        background: linear-gradient(to right, white, ${props => props.theme.green}); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
        cursor: pointer;
        color: ${props => props.theme.red};
        border:1px solid ${props => props.theme.green};
        box-shadow: 0 0 10px ${props => props.theme.green};
        
    }

    @media only screen and (max-width: 1350px) {
        padding: .7rem;
        font-size: 8px;
        border-top-right-radius: 0;
        border-bottom-left-radius: .5rem;
        margin-top: .1rem;
    } 

    @media only screen and (max-width: 1120px) {
        
        border-top-right-radius: .5rem;
        border-bottom-left-radius: 0;
    }
`

