import React from 'react'
import Icon from './Icon'
import styled from 'styled-components'
import faceBookIcon from '../../static/facebook-icon.png'
import twitterIcon from '../../static/twitter-icon.png'

export default function Socials() {
    return (
        <>
            <SocialsText>Join Us !</SocialsText>
            <SocialsIcons>
              <SocialIcon href="https://www.facebook.com/groups/753494562121735/">
                  <Icon  size={30} src={faceBookIcon} description={"Facebook Icon"}/>
              </SocialIcon>
              <SocialIcon href="https://twitter.com/itapresentsita">
                  <Icon size={30} src={twitterIcon} description={"Twitter Icon"}/>
              </SocialIcon>
            </SocialsIcons>
        </>
    )
}

const SocialsText = styled.div`
  color: black;
  font-family: 'Bangers', 'cursive';
  font-size: 2rem;

  @media only screen and (max-width: 1120px) {
    font-size: 1.3rem;
    margin-right: .3rem;
  }
`

const SocialsIcons = styled.div`
  > * {
    padding: .4rem; 

    @media only screen and (max-width: 1120px) {
        padding: .1rem;
    } 
  }

  
`

const SocialIcon = styled.a`
   @media only screen and (max-width: 1350px) {
        img {
          width: 40px;
          height: 40px;
        }
    } 

    @media only screen and (max-width: 1120px) {
      img {
        width: 30px;
        height: 30px;
      }
    }

`


