import React from 'react'
import styled from 'styled-components'

const Icon = ({ src, description, size }) => (
    <StyledIcon size={size} src={src} alt={description ? description : 'Image description' } />
)

const StyledIcon = styled.img`
  width: ${props => props.size ? props.size : '15px'}; 
  height: ${props => props.size ? props.size : '15px'};
  transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  border: 1px solid transparent;
  border-radius: .9rem;
  padding: .1rem;
`

export default Icon

